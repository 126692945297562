module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"1","matomoUrl":"//a.whatwedo.ch/","siteUrl":"https://www.whatwedo.ch"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.whatwedo.ch"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"whatwedo - Software Studio","short_name":"whatwedo","start_url":"/","background_color":"#fff","theme_color":"#000","display":"standalone","icon":"src/images/whatwedo-favicon-192x192.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"38c02d4884c5a3f9140d38d16309b2b5"},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"openGraph":{"type":"website","locale":"de_de","url":"https://www.whatwedo.ch","site_name":"whatwedo · Software Studio","title":"whatwedo · Software Studio","description":"Wir sind ein Software-Studio aus Bern, welches individuelle Web-Applikationen und Software auf Basis von modernen Technologien entwickelt."}},
    },{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[],"threshold":0.01,"rootMargin":"0px 0px -20px 0px"},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
