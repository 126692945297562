import { base } from '@theme-ui/presets';

export default {
    ...base,
    colors: {
        text: '#000',
        background: '#fff',
        primary: '#272727',
        secondary: '#e6e6e6',
        blueLight: '#00C0FF',
        blueDark: '#5558FF',
    },
    fonts: {
        ...base.fonts,
        body: '"OTR Grotesk", sans-serif',
        monospace: '"Roboto Mono", monospace',
        heading: '"OTR Grotesk", sans-serif',
    },
    fontWeights: {
        heading: 600,
    },
    sizes: {
        narrow: '750px',
        container: '900px',
        wide: '1200px',
        headerHeight: 'var(--site-navigation-panel-header-height-md)',
        headerHeightMobile: 'var(--site-navigation-panel-header-height-sm)',
    },
    forms: {
        input: {
            borderColor: 'background',
            borderWidth: '2px',
            backgroundColor: 'background',
            color: 'text',
            fontSize: ['1.1rem', '1.3rem'],
            fontFamily: 'monospace',
            borderRadius: 0,
            transition: 'border 0.4s ease',
            '&:focus': {
                borderColor: 'blueLight',
                outline: 'none',
            },
            '&::placeholder': {
                color: 'text',
                opacity: 0.4,
            }
        },
    },
    breakpoints: ['768px', '1024px', '1280px'],
    layout: {
        margin: '0 auto',
        padding: ['var(--layout-padding-sm)', 'var(--layout-padding-md)'],
        width: [
            'var(--layout-sm)', // < 768px viewport (S)
            'var(--layout-md)', // >= 768px viewport (M)
            'var(--layout-lg)', // >= 1024px viewport (L)
        ],
        maxWidth: 'var(--layout-max)', // no bigger than 1200px
    },
    zIndices: {
        content: 'var(--z-index-content)',
        navigation: 'var(--z-index-navigation)',
        navigationVeggie: 'var(--z-index-navigation-veggie)',
        header: 'var(--z-index-header)',
    },
    transitions: {
        customTransform: 'var(--transition-custom-transform)',
    },
    styles: {
        ...base.styles,
        root: {
            ...base.styles.root,
            '*::selection, .archive .section-light *::selection': {
                color: 'background',
                backgroundColor: 'primary',
            },
            '.archive *::selection, .color-invert *::selection, footer *::selection': {
                color: 'text',
                backgroundColor: 'background',
            },
            p: {
                fontSize: ['1.1rem', '1.3rem'],
                fontKerning: 'none',
                textWrap: 'pretty',
                lineHeight: 1.7,
                my: [4, 5],
            },
            ul: {
                fontSize: ['1.1rem', '1.3rem'],
                fontKerning: 'none',

                'li::marker': {
                    content: '"—"',
                }
            },
            ol: {
                fontSize: ['1.1rem', '1.3rem'],
                fontKerning: 'none',
            },
            strong: {
                fontSize: ['1.1rem', '1.3rem'],
                fontKerning: 'none',
                fontWeight: 'heading',
                my: [4, 5],
            },
            button: {
                border: 0,
                background: 'none',
                fontFamily: 'body',
                fontKerning: 'none',
                cursor: 'pointer',
                '&:focus': {
                    outline: 'none',
                },
            },
            a: {
                fontKerning: 'none',
                textDecoration: 'none',
                color: 'text',
            },
            'h1, h2, .u-heading-1, .u-heading-2': {
                my: 3,
                fontSize: ['2rem', '4rem'],
                lineHeight: 1.3,
                maxWidth: 'container',
                position: 'relative',
                '& + p': {
                    mt: [4, 5],
                },
            },
            'h3, .u-heading-3': {
                my: 3,
                fontSize: ['1.6rem', '2rem'],
                lineHeight: 1.3,
                '& + p': {
                    mt: 3,
                },
            },
            'h4, .u-heading-4': {
                fontSize: ['1.3rem', '1.7rem'],
                lineHeight: 1.3,
                '& + p': {
                    mt: [2, 3],
                },
            },
            'h1, h2, h3, h4, .u-heading-1, .u-heading-2, .u-heading-3, .u-heading-4': {
                fontFamily: 'heading',
                fontWeight: 'heading',
                marginTop: 0,
                marginBottom: 0,
                textWrap: 'balance',
                strong: {
                    fontWeight: 'heading',
                },
            },
            'h5, .u-heading-5': {
                fontFamily: '"Roboto Mono"',
                fontWeight: 'normal',
                fontSize: ['0.8rem', '1rem'],
                letterSpacing: '1px',
                marginBottom: 2,
                '+ *': {
                    marginTop: 1,
                },
            },
            pre: {
                fontFamily: '"Roboto Mono"',
                fontSize: ['0.9375rem', '1.25rem'],
                lineHeight: 1.5,
            },
        },
    },
};
